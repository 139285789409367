import vars from '../_vars'
import {openChats} from '../functions/openChats'
import {closeChats} from '../functions/closeChats'


/*const initToggleWidgets = () => {
  let type = vars.pageBody.data('name-widget');
  let currentWidget = $(`.dialog__chat-page-content-wrapper[data-id="${type}"]`);
  if(currentWidget.length) {
    vars.widgetWrapper.addClass('hidden');
    closeChats();
    setTimeout(() => {$(`.dialog__chat-page-content-wrapper[data-id="${type}"]`).removeClass('hidden')}, 200);
    setTimeout(openChats, 400);
  }
}

vars.closeWidget.on('click', () => {
  vars.widgetWrapper.addClass('hidden');
  closeChats();
  setTimeout(() => {$(`.dialog__chat-page-content-wrapper[data-id="#chat"]`).removeClass('hidden');}, 200);
  setTimeout(openChats, 400);
})

export default initToggleWidgets;*/
let prevWidget = null;
let getWidget = async (uid) => {
  if(!uid) return
  try {
    const response = await fetch('https://platform.bafsy.com/api/widgets/'+uid);
    const result = await response.json();
    initWidget(uid, result.html, result.css.replaceAll('%{widget_uid}', uid));
  } catch (error) {
    console.log(error);
  }
}

const initWidget = (uid, html, css) => {
  console.log(prevWidget)
  if(prevWidget == null) $('.dialog__chat-page-content-wrapper').removeClass('hidden')
  let myCss = $('<style>'+css+'</style>');
  let myHtml = $(html);
  let myUid = uid;
  myHtml.prepend(myCss);
  myHtml.addClass('widget hidden');
  $('.widget').addClass('hidden');
  $('.dialog__chat-page-content-wrapper').prepend(myHtml);
  $('.dialog__chat-page-content-wrapper[data-id="#chat"]').addClass('hidden');

  closeChats();
  setTimeout(() => {$('.widget#widget-'+uid).removeClass('hidden')}, 200);
  setTimeout(openChats, 400);

  let closeBtn = $('.widget#widget-'+uid).find('.close-widget');
  closeBtn.data('prev-widget', prevWidget);
  closeBtn.on('click', (e) => {
    $(e.currentTarget).parents().find('.widget#widget-'+uid).addClass('hidden');
    // Закрытие чата перед анимацией
    closeChats();
    // Анимация
    if($(e.currentTarget).data('prev-widget') == null || $(e.currentTarget).data('prev-widget') == myUid) {
      setTimeout(() => {
        $('.dialog__chat-page-content-wrapper[data-id="#chat"]').removeClass('hidden');
        $('.dialog__chat-page-contents-wrapper').addClass('hidden');
      }, 200);
    } else {
      setTimeout(() => {$('.widget#widget-'+$(e.currentTarget).data('prev-widget')).removeClass('hidden');}, 200);
    }
    // Открытие чата после анимации
    setTimeout(openChats, 400);
  })
  prevWidget = uid;
}


const initToggleWidgets = () => {
  let uid = vars.pageBody.data('name-widget');
  getWidget(uid);
  vars.pageBody.data('name-widget', null)
}

export default initToggleWidgets;


