
import vars from '../_vars'

// Изменение информации в хедере в зависмости от того, открыто ли мобильное меню
vars.collapseMenu.on('show.bs.collapse', () => {
  vars.header.addClass('menu-open');
  vars.pageBody.addClass('menu-open-body--hidden');
})
vars.collapseMenu.on('hide.bs.collapse', () => {
  vars.header.removeClass('menu-open');
  vars.pageBody.removeClass('menu-open-body--hidden');
})
