import vars from '../_vars'

let lists = []; // lists - массив строк вырезанных со списками
// let buttons = []; // buttons - массив кнопок
let links = []; // links - массив src и alt к ссылкам



// const handlingButton = (string) => {
//   let str = string;
//   let indexFindBtn = 0;
//   let posBtnStart = 0;
//   let posBtnEnd = 0;

//   while(true) {
//     indexFindBtn++
//     let substrStart = `BTNSTART${indexFindBtn} buy_`;
//     let substrEnd = `BTNEND${indexFindBtn}`;
//     posBtnStart = str.indexOf(substrStart);
//     posBtnEnd = str.indexOf(substrEnd);
//     if(posBtnStart == -1 && posBtnEnd == -1) break
//     else {
//       let buttonStr = str.slice(posBtnStart,posBtnEnd + substrEnd.length);
//       let buttonType = buttonStr.slice(substrStart.length, substrStart.length + 2);
//       let buttonContent = buttonStr.slice(buttonStr.indexOf(`${buttonType}+`) + 3, buttonStr.length - substrEnd.length);
//       buttons.push({
//         id: indexFindBtn,
//         buttonStr: buttonStr,
//         buttonContent: buttonContent,
//         buttonType: buttonType
//       })
//     }
//   }

//   const createButton = (string, content, type) => {
//     const button = $(`<div><div class="button__wrapper"><button class="open-widget" data-name-widget="#${type}">${content}</button></div></div>`);
//     return button
//   }

//   buttons.forEach((btn) => {
//     let button = createButton(btn.buttonStr, btn.buttonContent, btn.buttonType);
//     str = str.replace(btn.buttonStr, button.html())
//   })

//   return str;
// }

const handlingLink = (string) => {
  let str = string;
  let substrTitleStart = '[';
  let substrTitleEnd = ']';
  let substrHrefStart = '(';
  let substrHrefEnd = ')';
  let posTitleStart,
      posTitleEnd,
      posHrefStart,
      posHrefEnd,
      lastTitlePos,
      lastHrefPos = 0;

  const handlingTitleLink = () => {
    posTitleStart = str.indexOf(substrTitleStart, lastTitlePos);
    posTitleEnd = str.indexOf(substrTitleEnd, posTitleStart);
    lastTitlePos = posTitleEnd + 1;
    if(posTitleStart == -1) return false;
    else {
      let text = str.slice(posTitleStart + substrTitleStart.length,posTitleEnd);
      return text;
    }
  }

  const handlingHrefLink = () => {
    posHrefStart = str.indexOf(substrHrefStart, lastHrefPos);
    posHrefEnd = str.indexOf(substrHrefEnd, posHrefStart);
    lastHrefPos = posHrefEnd + 1;
    if(posHrefStart == -1) return false;
    else {
      let text = str.slice(posHrefStart + substrHrefStart.length,posHrefEnd);
      return text;
    }
  }

  while(true) {
    let title = handlingTitleLink();
    let href = handlingHrefLink();
    if(!title && !href) break;
    links.push({title,href})
  }

    links.forEach((link, i) => {
      let a = $(`<div><a target="_blank" href="${link.href}">${link.title}</a></div>`);
      str = str.replace(`[${link.title}](${link.href})`, a.html())
    })

  return str;
}

const handlingList = (string) => {
  let str = string;
  let indexFindList = 0;
  let posListStart = 0;
  let posListEnd = 0;
  while(true) {
    indexFindList++
    let substrStart = `LISTSTART${indexFindList}`;
    let substrEnd = `LISTEND${indexFindList}`;

    posListStart = str.indexOf(substrStart);
    posListEnd = str.indexOf(substrEnd);

    if(posListStart == -1 && posListEnd == -1) break
    else {
      let listStr = str.slice(posListStart,posListEnd + substrEnd.length);
      str = str.replace(listStr, `LIST${indexFindList}`);
      lists.push(listStr.trim());
    }
  }


  const createList = (listStr) => {
    const ul = $('<ul></ul>');
    let indexFindItem = 0;
    let posItemStart = 0;
    let posItemEnd = 0;
    while(true) {
      indexFindItem++
      let substrStart = `&&${indexFindItem}.`;
      let substrEnd = `.&&`;

      posItemStart = listStr.indexOf(substrStart);
      posItemEnd = listStr.indexOf(substrEnd, posItemStart);
      if(posItemStart == -1) {
        break
      } else {
        let itemStr = listStr.slice(posItemStart + substrStart.length,posItemEnd).trim()
        let li = $(`<li>${itemStr}</li>`)
        ul.append(li)
      }
    }

    return ul;
  }

  lists.forEach((list, i) => {
    let ul = createList(list);
    str = str.replace(`LIST${i+1}`, `<ul>${ul.html()}</ul>`)
  })


  return str;
}

const handlingLinkToWidget = (string) => {
  var str = string;
  var substrStart = '#';
  var substrEnd = ' ';
  var posStart = str.indexOf(substrStart);
  var posEnd = str.indexOf(substrEnd, posStart);
  if(posStart != -1) {
    var linkString = (posEnd != -1) ? str.slice(posStart,posEnd) : str.slice(posStart);
    str = str.replace(linkString, '');
    vars.pageBody.data('name-widget', linkString.slice(1));
  }

  return str;
}

const handlingAiResponse = (string) => {
  let str = string;
  let text = null;

  str = handlingList(str);
  str = handlingLink(str);
  str = handlingLinkToWidget(str);


  // $('#handlingText').html(str.slice(0, str.indexOf('<div class="button__wrapper">')));
  $('#handlingText').html(str);
  text = $('#handlingText').text()

  return {
    html: str.trim(),
    text: text.trim()
  }
}

export default handlingAiResponse;