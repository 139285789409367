
import vars from '../_vars'
import {openHelpTooltips} from '../functions/openHelpTooltips'
import {closeHelpTooltips} from '../functions/closeHelpTooltips'

// Активная ссылка при стандартном переходе по страницам
const checkCurrentPage = () => {
  const currentPath = location.pathname
  const link = $(`#menu a[href="${currentPath}"]`);
  if (link.length) {
    link.addClass('active')
  }
}
checkCurrentPage();


// Активная ссылка и др. действия при открытии и закрытии модальных окн
$('.modal').each((i, modal) => {
  const id = $(modal).attr('id');
  const myModal = $(`#${id}`);
  $(myModal).on('show.bs.modal', (e) => {
    vars.collapseMenu.find('a').removeClass('active');
    vars.collapseMenu.find(`a[data-bs-target="#${id}"]`).addClass('active');
    vars.collapseMenu.collapse('hide');
    if(id == 'agreeAudioModal') setTimeout(() => {$('.modal-backdrop').addClass('agreeAudio-open');}, 1)
    if(id == 'helpModal') {
      vars.header.find(`a[data-bs-target="#${id}"]`).addClass('active');
      if(vars.voiceTapSwitch.find('#chat-tab').hasClass('active')) {
        vars.voiceTapSwitch.find('#voice-tab').click();
      }
      setTimeout(() => {$('.modal-backdrop').addClass('help-open');}, 1)
      openHelpTooltips();
    } else {
      $('.section-dialog').css({opacity: 0})
    }
  })

  $(myModal).on('hide.bs.modal', (e) => {
    vars.collapseMenu.find(`a[data-bs-target="#${id}"]`).removeClass('active');
    if(id == 'helpModal') {
      vars.header.find(`a[data-bs-target="#${id}"]`).removeClass('active');
      closeHelpTooltips();
    } else {
      $('.section-dialog').css({opacity: 1})
    }
    checkCurrentPage();
  })
})