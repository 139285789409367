import vars from '../_vars'
import {closeChats} from '../functions/closeChats'

// Проверка размера экрана
const checkWidth = () => {
  let width = $(window).width();
  let statusBig = sessionStorage.getItem("chatOpened");
  let statusSmall = sessionStorage.getItem("chatSmallOpened");
  if(width <= 767) {
    // Чат который работает на ширине от 767px и выше
    if(statusBig == 'true') closeChats();
  } else {
    // Чат который работает на ширине до 767px
    if(statusSmall == 'true') vars.voiceTapSwitch.find('#voice-tab').click();
  }
}

if($(window).width() <=767) {
  vars.voiceTapSwitch.find('#chat-tab').click();
}

checkWidth();
$(window).on('resize', () => {
  checkWidth();
})