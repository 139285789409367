import vars from '../_vars'

const observeObject = function () {
	const _class = {
		init: function (selector, callback) {
			let element = document.querySelector(selector);

			try {
				let observer = new MutationObserver(function (mutations) {
						mutations.forEach(function (mutation) {
              if(mutation.attributeName == 'class') callback(mutation.target, mutation.attributeName, mutation.oldValue);
						});
					});

				observer.observe(element, {
					attributes: true,
					subtree: true,
					attributeOldValue: true
				});
			} catch (z) {
				element.addEventListener('DOMAttrModified', function (e) {
					callback(e.target, e.prevValue);
				}, false);
			}
		}
	};

	return _class;
}();


const viewBoxObj = {
  circle: '0 0 200 200',
  cloud: '0 0 260 230',
  equalizer: '0 0 290 131',
}

$(function () {
	observeObject.init('#interfaceIcon', function (target, oldValue) {
    if($(target).hasClass('circle')) {
      $(target).attr('viewBox', viewBoxObj['circle']);
      $(target).find('.rect').attr('rx', '100%');
    } else if($(target).hasClass('cloud')) {
      $(target).attr('viewBox', viewBoxObj['cloud'])
      $(target).find('.rect').attr('rx', '100%');
    } else if($(target).hasClass('equalizer')) {
      $(target).attr('viewBox', viewBoxObj['equalizer'])
      $(target).find('.rect').attr('rx', '35.5');
    }
	});
});