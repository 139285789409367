import vars from '../_vars'
import {openChats} from '../functions/openChats'
import {closeChats} from '../functions/closeChats'


$(window).on('load', () => {
  let statusSwitchType = sessionStorage.getItem("typeChecked");
  if(statusSwitchType) $(`#${statusSwitchType}`).click();

  let statusChat = sessionStorage.getItem("chatOpened");
  if(statusChat == 'true') vars.openChat.click();
  else if (statusChat == 'false') vars.closeChat.click()
  else vars.closeChat.click()

});


vars.voiceTapSwitch.find('.nav-link').on('click', (e) => {
  sessionStorage.setItem('typeChecked', e.currentTarget.id);


  if(e.currentTarget.id == 'chat-tab') {
    sessionStorage.setItem('chatSmallOpened', 'true');
    vars.recordingButton.attr('data-visible', 'false');
    vars.toggleAudioBtn.attr('data-visible', 'false');
  } else {
    sessionStorage.setItem('chatSmallOpened', 'false');
    if(vars.toggleAudioBtn.attr('data-status') == 'running') {
      vars.recordingButton.attr('data-visible', 'false');
      vars.toggleAudioBtn.attr('data-visible', 'true');
    } else {
      vars.recordingButton.attr('data-visible', 'true');
      vars.toggleAudioBtn.attr('data-visible', 'false');
    }
  };
});

vars.openChat.on('click', (e) => {
  openChats();
  sessionStorage.setItem('chatOpened', 'true');
});

vars.closeChat.on('click', (e) => {
  closeChats();
  sessionStorage.setItem('chatOpened', 'false');
});
